.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(-100%);
  -moz-transform: translateZ(-100%);
  -o-transform: translateZ(-100%);
  -ms-transform: translateZ(-100%);
  transform: translateZ(-100%);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.cube figure {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  text-align: center;
  line-height: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
}
.cube figure.front {
  -webkit-transform: rotateY(0deg) translateZ(-3em);
  -moz-transform: rotateY(0deg) translateZ(-3em);
  -o-transform: rotateY(0deg) translateZ(-3em);
  -ms-transform: rotateY(0deg) translateZ(-3em);
  transform: rotateY(0deg) translateZ(-3em);
}
.cube figure.back {
  -webkit-transform: rotateX(180deg) translateZ(-3em);
  -moz-transform: rotateX(180deg) translateZ(-3em);
  -o-transform: rotateX(180deg) translateZ(-3em);
  -ms-transform: rotateX(180deg) translateZ(-3em);
  transform: rotateX(180deg) translateZ(-3em);
}
.cube figure.right {
  -webkit-transform: rotateY(90deg) translateZ(-3em);
  -moz-transform: rotateY(90deg) translateZ(-3em);
  -o-transform: rotateY(90deg) translateZ(-3em);
  -ms-transform: rotateY(90deg) translateZ(-3em);
  transform: rotateY(90deg) translateZ(-3em);
}
.cube figure.left {
  -webkit-transform: rotateY(-90deg) translateZ(-3em);
  -moz-transform: rotateY(-90deg) translateZ(-3em);
  -o-transform: rotateY(-90deg) translateZ(-3em);
  -ms-transform: rotateY(-90deg) translateZ(-3em);
  transform: rotateY(-90deg) translateZ(-3em);
}
.cube figure.top {
  -webkit-transform: rotateX(90deg) translateZ(-3em);
  -moz-transform: rotateX(90deg) translateZ(-3em);
  -o-transform: rotateX(90deg) translateZ(-3em);
  -ms-transform: rotateX(90deg) translateZ(-3em);
  transform: rotateX(90deg) translateZ(-3em);
}
.cube figure.bottom {
  -webkit-transform: rotateX(-90deg) translateZ(-3em);
  -moz-transform: rotateX(-90deg) translateZ(-3em);
  -o-transform: rotateX(-90deg) translateZ(-3em);
  -ms-transform: rotateX(-90deg) translateZ(-3em);
  transform: rotateX(-90deg) translateZ(-3em);
}
::selection {
  background: #fe6d8c;
}
::-moz-selection {
  background: #fe6d8c;
}
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Brandon Grotesque W01', sans-serif;
  font-weight: 200;
  height: 100%;
  width: 100%;
  color: #fff2e0;
}
@media screen and (max-width: 48em) {
  html,
  body {
    background-image: url("../img/bg.jpg");
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    background-position: centr center;
    height: auto;
    overflow-x: hidden;
  }
}
body {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #fe6d8c;
  -webkit-transition: color 175ms ease-in;
  -moz-transition: color 175ms ease-in;
  -o-transition: color 175ms ease-in;
  -ms-transition: color 175ms ease-in;
  transition: color 175ms ease-in;
}
a:hover {
  color: #ffccd7;
}
#map-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
#map-container {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 48em) {
  #map-container {
    display: none;
  }
}
#map-container #map-canvas {
  position: absolute;
  bottom: -5%;
  right: -5%;
  height: 120%;
  width: 120%;
}
#map-container #map-canvas #map {
  position: absolute;
  right: 0;
}
#map-container #pink-field {
  width: 52.5px;
  height: 40.5px;
  position: absolute;
  top: 47%;
  right: 27.2%;
  z-index: 15;
  -webkit-animation: glow 1275ms ease-in infinite;
  -moz-animation: glow 1275ms ease-in infinite;
  -o-animation: glow 1275ms ease-in infinite;
  -ms-animation: glow 1275ms ease-in infinite;
  animation: glow 1275ms ease-in infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  -ms-animation-direction: alternate;
  animation-direction: alternate;
}
#map-container #nameplate {
  width: 311px;
  height: 50px;
  position: absolute;
  top: 43%;
  right: 22.2%;
  z-index: 15;
  -webkit-animation: glow 1275ms ease-in infinite;
  -moz-animation: glow 1275ms ease-in infinite;
  -o-animation: glow 1275ms ease-in infinite;
  -ms-animation: glow 1275ms ease-in infinite;
  animation: glow 1275ms ease-in infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  -ms-animation-direction: alternate;
  animation-direction: alternate;
}
#map-container #nameplate text {
  font-family: 'Brandon Grotesque W01', sans-serif;
  font-weight: 100;
}
#map-container #labels {
  width: 452px;
  height: 607px;
  position: absolute;
  top: 43%;
  right: 22.2%;
  z-index: 1500;
}
#map-container .map-location {
  position: absolute;
  top: 47%;
  right: 22.2%;
  font-weight: 200;
  z-index: 1100;
  font-size: 16px;
  -webkit-animation: glow 1275ms ease-in infinite;
  -moz-animation: glow 1275ms ease-in infinite;
  -o-animation: glow 1275ms ease-in infinite;
  -ms-animation: glow 1275ms ease-in infinite;
  animation: glow 1275ms ease-in infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  -ms-animation-direction: alternate;
  animation-direction: alternate;
}
#map-container .map-location#google-maps {
  right: 30%;
}
#map-container .map-location#foursquare {
  right: 24.5%;
}
#map-container #cloud-canvas {
  pointer-events: none;
}
#map-container .cloud {
  position: absolute;
  z-index: 100;
  background-image: url("../img/cloud-1.png");
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
#page-container {
  width: 43.875em;
  position: relative;
  z-index: 1;
  min-height: 100%;
  margin-bottom: -30.25em;
}
#page-container::after {
  content: "";
  display: block;
}
@media screen and (max-width: 48em) {
  #page-container {
    width: auto;
    min-height: 0;
    margin-bottom: 0;
  }
}
#footer {
  height: 25em;
  color: #fff2e0;
  position: relative;
  z-index: 100;
}
@media screen and (max-width: 48em) {
  #footer {
    height: auto;
  }
}
#page-container::after {
  height: 29em;
}
@media screen and (max-width: 48em) {
  #page-container::after {
    height: auto;
  }
}
#header-component,
#lightbox-mount header {
  padding: 7em 0 0 7.5em;
  width: 40em;
}
@media screen and (max-width: 48em) {
  #header-component,
  #lightbox-mount header {
    width: auto;
    padding: 7em 0 0 2em !important;
  }
}
#header-component h1,
#lightbox-mount h2 {
  font-weight: 100;
  font-size: 6.8em;
  line-height: 1em;
  width: 3.4em;
  color: #ffe5c2;
  z-index: 1500;
  position: absolute;
}
@media screen and (max-width: 48em) {
  #header-component h1,
  #lightbox-mount h2 {
    height: auto;
    width: auto;
    position: relative;
  }
}
#publication-callouts {
  margin-top: 24.5em;
}
@media screen and (max-width: 48em) {
  #publication-callouts {
    margin-top: 0.5em !important;
  }
}
#publication-callouts p {
  font-size: 1.1em;
  letter-spacing: 0.05em;
  padding: 0;
  margin: 0;
  display: inline-block;
}
#publication-callouts p+p {
  margin-top: 0.2125em;
}
#publication-callouts p strong {
  font-weight: 200;
  text-transform: uppercase;
}
#publication-callouts .more {
  font-size: 0.6em;
  text-transform: uppercase;
  margin: 0 0 0 1em;
  font-weight: 700;
  display: inline-block;
}
#social-box {
  margin: 4.5em 0 0 7.5em;
}
#social-box h2 {
  font-size: 1.5em;
  letter-spacing: 0.15em;
  margin-bottom: 0.1em;
}
#social-box h2 small {
  display: block;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 0.4em;
  letter-spacing: 0.15em;
}
#social-box #social-links {
  margin-top: 0.1em;
}
#social-box #social-links .fa {
  font-size: 2em;
}
#social-box #social-links a+a {
  margin-left: 1em;
}
@media screen and (max-width: 48em) {
  #social-box {
    margin: 4.5em 0 0 2em;
  }
}
#twitter-component-wrapper {
  position: relative;
  margin: 5em 0 2em 7.5em;
  height: 20.125em;
  left: 0;
}
@media screen and (max-width: 48em) {
  #twitter-component-wrapper {
    margin: 1em 0 2em 2em;
    height: auto;
  }
}
#twitter-component {
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 48em) {
  #twitter-component {
    position: relative;
  }
}
#twitter-component .tweet-item {
  vertical-align: bottom;
  width: 26.875em;
}
@media screen and (max-width: 48em) {
  #twitter-component .tweet-item {
    width: 100%;
  }
}
#twitter-component .tweet-item .user,
#twitter-component .tweet-item .time {
  font-size: 0.9em;
  letter-spacing: 0.0725em;
}
#twitter-component .tweet-item .user {
  font-weight: 700;
  text-transform: uppercase;
}
#twitter-component .tweet-item .time {
  letter-spacing: 0.25em;
  margin-left: 1em;
}
#twitter-component .tweet-item p {
  font-size: 1.025em;
  margin: 0;
}
#twitter-component .tweet-item+.tweet-item {
  margin-top: 0.5em;
}
#twitter-callout {
  width: 50.875em;
  height: 3em;
  line-height: 3em;
  position: relative;
  text-align: left;
  padding-left: 7.5em;
  text-transform: uppercase;
  background: rgba(71,37,38,0.5);
  background: -webkit-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -webkit-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%);
  background: -moz-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -moz-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%);
  background: -o-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -o-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%);
  background: -ms-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -ms-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%);
  background: linear-gradient(to bottom right, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), linear-gradient(to right, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%);
}
#twitter-callout .gold-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #c5baa8;
  background: -webkit-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -moz-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -o-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -ms-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: linear-gradient(to right, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
}
#twitter-callout span {
  letter-spacing: 0.2em;
  font-size: 0.6em;
}
@media screen and (max-width: 48em) {
  #twitter-callout {
    background: none;
    width: 50%;
    height: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    line-height: 1;
    font-size: 1em;
    padding-left: 2em;
  }
  #twitter-callout .gold-border {
    display: none;
  }
}
@media screen and (max-width: 72em) {
  .instagram-list {
    font-size: 50%;
  }
  #lightbox-mount #lightbox-meta-box {
    font-size: 50%;
  }
  #lightbox-mount #lightbox-meta-box #lightbox-meta-wrapper {
    font-size: 150%;
  }
}
#instagram-wrapper {
  position: fixed;
  right: -2em;
  bottom: -8em;
  z-index: 100;
}
@media screen and (max-width: 85.375em) {
  #instagram-wrapper #lightbox-meta-wrapper {
    font-size: 70%;
  }
}
@media screen and (max-width: 48em) {
  #instagram-wrapper {
    margin-top: 1em;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    font-size: 100%;
    width: 100%;
  }
  #instagram-wrapper #instagram-component {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
  }
  #instagram-wrapper #instagram-component::before {
    padding-top: 0;
  }
  #instagram-wrapper #instagram-component .instagram-list {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    width: 100%;
  }
  #instagram-wrapper #instagram-component .instagram-list .box {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    float: left;
  }
  #instagram-wrapper #instagram-component .instagram-list .box.box-lg,
  #instagram-wrapper #instagram-component .instagram-list .box.box-md,
  #instagram-wrapper #instagram-component .instagram-list .box.box-sm {
    width: 33.333333333333336%;
    height: auto;
  }
  #instagram-wrapper #instagram-component .instagram-list .box .card-wrapper {
    height: 0;
    padding-bottom: 100%;
    width: 100%;
  }
}
#instagram-component {
  display: inline-block;
}
#instagram-component::before {
  display: block;
  content: ' ';
  padding: 0;
  margin: 0;
  padding-top: 100%;
}
#instagram-component .instagram-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
#instagram-component .box {
  width: 15em;
  height: 15em;
  position: absolute;
  cursor: pointer;
  -webkit-transition: -webkit-transform 125ms ease-in, bottom 175ms ease-in, right 175ms ease-in, width 175ms ease-in, height 175ms ease-in, opacity 175ms ease-in;
  -moz-transition: -moz-transform 125ms ease-in, bottom 175ms ease-in, right 175ms ease-in, width 175ms ease-in, height 175ms ease-in, opacity 175ms ease-in;
  -o-transition: -o-transform 125ms ease-in, bottom 175ms ease-in, right 175ms ease-in, width 175ms ease-in, height 175ms ease-in, opacity 175ms ease-in;
  -ms-transition: -ms-transform 125ms ease-in, bottom 175ms ease-in, right 175ms ease-in, width 175ms ease-in, height 175ms ease-in, opacity 175ms ease-in;
  transition: transform 125ms ease-in, bottom 175ms ease-in, right 175ms ease-in, width 175ms ease-in, height 175ms ease-in, opacity 175ms ease-in;
}
#instagram-component .box.dim {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
@media screen and (max-width: 48em) {
  #instagram-component .box.dim {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
#instagram-component .box.lightbox-preview {
  z-index: 1500;
  width: 50em !important;
  height: 50em !important;
  bottom: 6em !important;
  right: 5em !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  transform: scale(1) !important;
}
@media screen and (max-width: 48em) {
  #instagram-component .box.lightbox-preview {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    width: 100% !important;
    height: auto !important;
  }
}
#instagram-component .box:hover {
  z-index: 1000;
}
#instagram-component .box .cube figure {
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.2);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}
#instagram-component .box.box-sm {
  width: 4.999999999999995em;
  height: 4.999999999999995em;
}
#instagram-component .box.box-sm .cube figure.front {
  -webkit-transform: rotateY(0deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateY(0deg) translateZ(-2.499999999999997em);
  -o-transform: rotateY(0deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateY(0deg) translateZ(-2.499999999999997em);
  transform: rotateY(0deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-sm .cube figure.back {
  -webkit-transform: rotateX(180deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateX(180deg) translateZ(-2.499999999999997em);
  -o-transform: rotateX(180deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateX(180deg) translateZ(-2.499999999999997em);
  transform: rotateX(180deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-sm .cube figure.right {
  -webkit-transform: rotateY(90deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateY(90deg) translateZ(-2.499999999999997em);
  -o-transform: rotateY(90deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateY(90deg) translateZ(-2.499999999999997em);
  transform: rotateY(90deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-sm .cube figure.left {
  -webkit-transform: rotateY(-90deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateY(-90deg) translateZ(-2.499999999999997em);
  -o-transform: rotateY(-90deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateY(-90deg) translateZ(-2.499999999999997em);
  transform: rotateY(-90deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-sm .cube figure.top {
  -webkit-transform: rotateX(-90deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateX(-90deg) translateZ(-2.499999999999997em);
  -o-transform: rotateX(-90deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateX(-90deg) translateZ(-2.499999999999997em);
  transform: rotateX(-90deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-sm .cube figure.bottom {
  -webkit-transform: rotateX(90deg) translateZ(-2.499999999999997em);
  -moz-transform: rotateX(90deg) translateZ(-2.499999999999997em);
  -o-transform: rotateX(90deg) translateZ(-2.499999999999997em);
  -ms-transform: rotateX(90deg) translateZ(-2.499999999999997em);
  transform: rotateX(90deg) translateZ(-2.499999999999997em);
}
#instagram-component .box.box-md {
  width: 10.000000000000004em;
  height: 10.000000000000004em;
}
#instagram-component .box.box-md .cube figure.front {
  -webkit-transform: rotateY(0deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateY(0deg) translateZ(-5.000000000000002em);
  -o-transform: rotateY(0deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateY(0deg) translateZ(-5.000000000000002em);
  transform: rotateY(0deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-md .cube figure.back {
  -webkit-transform: rotateX(180deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateX(180deg) translateZ(-5.000000000000002em);
  -o-transform: rotateX(180deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateX(180deg) translateZ(-5.000000000000002em);
  transform: rotateX(180deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-md .cube figure.right {
  -webkit-transform: rotateY(90deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateY(90deg) translateZ(-5.000000000000002em);
  -o-transform: rotateY(90deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateY(90deg) translateZ(-5.000000000000002em);
  transform: rotateY(90deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-md .cube figure.left {
  -webkit-transform: rotateY(-90deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateY(-90deg) translateZ(-5.000000000000002em);
  -o-transform: rotateY(-90deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateY(-90deg) translateZ(-5.000000000000002em);
  transform: rotateY(-90deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-md .cube figure.top {
  -webkit-transform: rotateX(-90deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateX(-90deg) translateZ(-5.000000000000002em);
  -o-transform: rotateX(-90deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateX(-90deg) translateZ(-5.000000000000002em);
  transform: rotateX(-90deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-md .cube figure.bottom {
  -webkit-transform: rotateX(90deg) translateZ(-5.000000000000002em);
  -moz-transform: rotateX(90deg) translateZ(-5.000000000000002em);
  -o-transform: rotateX(90deg) translateZ(-5.000000000000002em);
  -ms-transform: rotateX(90deg) translateZ(-5.000000000000002em);
  transform: rotateX(90deg) translateZ(-5.000000000000002em);
}
#instagram-component .box.box-lg {
  width: 15em;
  height: 15em;
}
#instagram-component .box.box-lg .cube figure.front {
  -webkit-transform: rotateY(0deg) translateZ(-7.5em);
  -moz-transform: rotateY(0deg) translateZ(-7.5em);
  -o-transform: rotateY(0deg) translateZ(-7.5em);
  -ms-transform: rotateY(0deg) translateZ(-7.5em);
  transform: rotateY(0deg) translateZ(-7.5em);
}
#instagram-component .box.box-lg .cube figure.back {
  -webkit-transform: rotateX(180deg) translateZ(-7.5em);
  -moz-transform: rotateX(180deg) translateZ(-7.5em);
  -o-transform: rotateX(180deg) translateZ(-7.5em);
  -ms-transform: rotateX(180deg) translateZ(-7.5em);
  transform: rotateX(180deg) translateZ(-7.5em);
}
#instagram-component .box.box-lg .cube figure.right {
  -webkit-transform: rotateY(90deg) translateZ(-7.5em);
  -moz-transform: rotateY(90deg) translateZ(-7.5em);
  -o-transform: rotateY(90deg) translateZ(-7.5em);
  -ms-transform: rotateY(90deg) translateZ(-7.5em);
  transform: rotateY(90deg) translateZ(-7.5em);
}
#instagram-component .box.box-lg .cube figure.left {
  -webkit-transform: rotateY(-90deg) translateZ(-7.5em);
  -moz-transform: rotateY(-90deg) translateZ(-7.5em);
  -o-transform: rotateY(-90deg) translateZ(-7.5em);
  -ms-transform: rotateY(-90deg) translateZ(-7.5em);
  transform: rotateY(-90deg) translateZ(-7.5em);
}
#instagram-component .box.box-lg .cube figure.top {
  -webkit-transform: rotateX(-90deg) translateZ(-7.5em);
  -moz-transform: rotateX(-90deg) translateZ(-7.5em);
  -o-transform: rotateX(-90deg) translateZ(-7.5em);
  -ms-transform: rotateX(-90deg) translateZ(-7.5em);
  transform: rotateX(-90deg) translateZ(-7.5em);
}
#instagram-component .box.box-lg .cube figure.bottom {
  -webkit-transform: rotateX(90deg) translateZ(-7.5em);
  -moz-transform: rotateX(90deg) translateZ(-7.5em);
  -o-transform: rotateX(90deg) translateZ(-7.5em);
  -ms-transform: rotateX(90deg) translateZ(-7.5em);
  transform: rotateX(90deg) translateZ(-7.5em);
}
#instagram-component .box.box-1 {
  right: 0;
  bottom: 0;
}
#instagram-component .box.box-2 {
  right: 10.000000000000004em;
  bottom: 0;
}
#instagram-component .box.box-3 {
  right: 19.999999999999996em;
  bottom: 4.999999999999995em;
}
#instagram-component .box.box-4 {
  right: 0;
  bottom: 10.000000000000004em;
}
#instagram-component .box.box-5 {
  right: 0;
  bottom: 19.999999999999996em;
}
#instagram-component .box.box-6 {
  right: 0;
  bottom: 40.00000000000001em;
}
#instagram-component .box.box-7 {
  right: 4.999999999999995em;
  bottom: 40.00000000000001em;
}
#instagram-component .box.box-8 {
  right: 10.000000000000004em;
  bottom: 10.000000000000004em;
}
#instagram-component .box.box-9 {
  right: 25.000000000000004em;
  bottom: 10.000000000000004em;
}
#instagram-component .box.box-10 {
  right: 0;
  bottom: 45em;
}
#instagram-component .box.box-11 {
  right: 10.000000000000004em;
  bottom: 25.000000000000004em;
}
#instagram-component .box.box-12 {
  right: 0;
  bottom: 30em;
}
#instagram-component .box.box-13 {
  right: 10.000000000000004em;
  bottom: 34.99999999999999em;
}
#instagram-component .box.box-14 {
  right: 19.999999999999996em;
  bottom: 25.000000000000004em;
}
#instagram-component .box.box-15 {
  right: 25.000000000000004em;
  bottom: 30em;
}
#instagram-component .box.pushed {
  z-index: 1000;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  pointer-events: none;
}
#instagram-component .box.pushed.box-1 {
  -webkit-transform: translateY(4em);
  -moz-transform: translateY(4em);
  -o-transform: translateY(4em);
  -ms-transform: translateY(4em);
  transform: translateY(4em);
}
#instagram-component .box.pushed.box-2 {
  -webkit-transform: translateY(4em);
  -moz-transform: translateY(4em);
  -o-transform: translateY(4em);
  -ms-transform: translateY(4em);
  transform: translateY(4em);
}
#instagram-component .box.pushed.box-3 {
  -webkit-transform: translateY(4em);
  -moz-transform: translateY(4em);
  -o-transform: translateY(4em);
  -ms-transform: translateY(4em);
  transform: translateY(4em);
}
#instagram-component .box.pushed.box-4 {
  -webkit-transform: translateX(5em);
  -moz-transform: translateX(5em);
  -o-transform: translateX(5em);
  -ms-transform: translateX(5em);
  transform: translateX(5em);
}
#instagram-component .box.pushed.box-5 {
  -webkit-transform: translateX(5em);
  -moz-transform: translateX(5em);
  -o-transform: translateX(5em);
  -ms-transform: translateX(5em);
  transform: translateX(5em);
}
#instagram-component .box.pushed.box-6 {
  -webkit-transform: translateX(0em);
  -moz-transform: translateX(0em);
  -o-transform: translateX(0em);
  -ms-transform: translateX(0em);
  transform: translateX(0em);
}
#instagram-component .box.pushed.box-7 {
  -webkit-transform: translateX(5em) translateY(-5em);
  -moz-transform: translateX(5em) translateY(-5em);
  -o-transform: translateX(5em) translateY(-5em);
  -ms-transform: translateX(5em) translateY(-5em);
  transform: translateX(5em) translateY(-5em);
}
#instagram-component .box.pushed.box-8 {
  -webkit-transform: translateY(-46em) translateX(5em);
  -moz-transform: translateY(-46em) translateX(5em);
  -o-transform: translateY(-46em) translateX(5em);
  -ms-transform: translateY(-46em) translateX(5em);
  transform: translateY(-46em) translateX(5em);
}
#instagram-component .box.pushed.box-9 {
  -webkit-transform: translateX(5em) translateY(-46em);
  -moz-transform: translateX(5em) translateY(-46em);
  -o-transform: translateX(5em) translateY(-46em);
  -ms-transform: translateX(5em) translateY(-46em);
  transform: translateX(5em) translateY(-46em);
}
#instagram-component .box.pushed.box-10 {
  -webkit-transform: translateX(0em);
  -moz-transform: translateX(0em);
  -o-transform: translateX(0em);
  -ms-transform: translateX(0em);
  transform: translateX(0em);
}
#instagram-component .box.pushed.box-11 {
  -webkit-transform: translateX(-45em);
  -moz-transform: translateX(-45em);
  -o-transform: translateX(-45em);
  -ms-transform: translateX(-45em);
  transform: translateX(-45em);
}
#instagram-component .box.pushed.box-12 {
  -webkit-transform: translateX(5em);
  -moz-transform: translateX(5em);
  -o-transform: translateX(5em);
  -ms-transform: translateX(5em);
  transform: translateX(5em);
}
#instagram-component .box.pushed.box-13 {
  -webkit-transform: translateX(10em);
  -moz-transform: translateX(10em);
  -o-transform: translateX(10em);
  -ms-transform: translateX(10em);
  transform: translateX(10em);
}
#instagram-component .box.pushed.box-14 {
  -webkit-transform: translateX(-35em) translateY(-31em);
  -moz-transform: translateX(-35em) translateY(-31em);
  -o-transform: translateX(-35em) translateY(-31em);
  -ms-transform: translateX(-35em) translateY(-31em);
  transform: translateX(-35em) translateY(-31em);
}
#instagram-component .box.pushed.box-15 {
  -webkit-transform: translateX(-25em) translateY(-31em);
  -moz-transform: translateX(-25em) translateY(-31em);
  -o-transform: translateX(-25em) translateY(-31em);
  -ms-transform: translateX(-25em) translateY(-31em);
  transform: translateX(-25em) translateY(-31em);
}
@media screen and (max-width: 48em) {
  #instagram-component .box.pushed.box-1 {
    display: none;
  }
  #instagram-component .box.pushed.box-2 {
    display: none;
  }
  #instagram-component .box.pushed.box-3 {
    display: none;
  }
  #instagram-component .box.pushed.box-4 {
    display: none;
  }
  #instagram-component .box.pushed.box-5 {
    display: none;
  }
  #instagram-component .box.pushed.box-6 {
    display: none;
  }
  #instagram-component .box.pushed.box-7 {
    display: none;
  }
  #instagram-component .box.pushed.box-8 {
    display: none;
  }
  #instagram-component .box.pushed.box-9 {
    display: none;
  }
  #instagram-component .box.pushed.box-10 {
    display: none;
  }
  #instagram-component .box.pushed.box-11 {
    display: none;
  }
  #instagram-component .box.pushed.box-12 {
    display: none;
  }
  #instagram-component .box.pushed.box-13 {
    display: none;
  }
  #instagram-component .box.pushed.box-14 {
    display: none;
  }
  #instagram-component .box.pushed.box-15 {
    display: none;
  }
}
#instagram-component .box .card-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
#instagram-component .box .card-wrapper img {
  width: 100%;
  height: 100%;
}
#instagram-component .box .card-wrapper img.full-res {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 48em) {
  .lightboxed #footer,
  .lightboxed #page-container {
    display: none;
  }
}
#lightbox-cheech {
  -webkit-animation: fadeIn 275ms ease-in;
  -moz-animation: fadeIn 275ms ease-in;
  -o-animation: fadeIn 275ms ease-in;
  -ms-animation: fadeIn 275ms ease-in;
  animation: fadeIn 275ms ease-in;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -ms-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  background-color: rgba(0,0,0,0.8);
  z-index: 900;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  display: none;
}
#lightbox-mount {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1500;
}
@media screen and (max-width: 48em) {
  #lightbox-mount {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
  }
}
#lightbox-meta-box {
  z-index: 1500;
  position: fixed;
  right: 55em;
  bottom: 1em;
  height: 50em;
  text-align: right;
  display: table;
}
@media screen and (max-width: 48em) {
  #lightbox-meta-box {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    height: auto;
    display: block;
    text-align: left;
    padding: 2em;
  }
}
#lightbox-meta-box #lightbox-meta-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 36.875em;
}
@media screen and (max-width: 48em) {
  #lightbox-meta-box #lightbox-meta-wrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;
    height: auto;
    width: auto;
    display: block;
  }
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-header {
  font-weight: 300;
  margin: 0 0 1em;
  padding: 0;
  -webkit-animation: dropIn 175ms ease-in;
  -moz-animation: dropIn 175ms ease-in;
  -o-animation: dropIn 175ms ease-in;
  -ms-animation: dropIn 175ms ease-in;
  animation: dropIn 175ms ease-in;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -ms-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-delay: 175ms;
  -moz-animation-delay: 175ms;
  -o-animation-delay: 175ms;
  -ms-animation-delay: 175ms;
  animation-delay: 175ms;
  text-align: right;
  position: relative;
  display: block;
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .close-wrapper {
  height: 3em;
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .fa {
  color: #fe6d8c;
  margin-right: 0.5em;
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .title {
  display: block;
  text-align: right;
}
@media screen and (max-width: 48em) {
  #lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .title {
    text-align: left;
  }
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .close-page {
  font-size: 1.5em;
  left: auto;
  text-align: right;
  right: 0;
}
@media screen and (max-width: 48em) {
  #lightbox-meta-box #lightbox-meta-wrapper .lightbox-header .close-page {
    position: relative;
    top: auto;
    right: auto;
    float: none;
    clear: both;
    margin: 0 0 2em;
    text-align: left;
  }
}
#lightbox-meta-box #lightbox-meta-wrapper .lightbox-user {
  text-transform: uppercase;
  font-size: 0.625em;
  font-weight: 700;
  margin: 0;
  padding: 0;
  -webkit-animation: dropIn 275ms ease-in;
  -moz-animation: dropIn 275ms ease-in;
  -o-animation: dropIn 275ms ease-in;
  -ms-animation: dropIn 275ms ease-in;
  animation: dropIn 275ms ease-in;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -ms-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-delay: 175ms;
  -moz-animation-delay: 175ms;
  -o-animation-delay: 175ms;
  -ms-animation-delay: 175ms;
  animation-delay: 175ms;
}
#lightbox-meta-box #lightbox-meta-wrapper p {
  -webkit-animation: dropIn 175ms ease-in;
  -moz-animation: dropIn 175ms ease-in;
  -o-animation: dropIn 175ms ease-in;
  -ms-animation: dropIn 175ms ease-in;
  animation: dropIn 175ms ease-in;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -ms-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-delay: 375ms;
  -moz-animation-delay: 375ms;
  -o-animation-delay: 375ms;
  -ms-animation-delay: 375ms;
  animation-delay: 375ms;
  font-size: 1.75em;
  margin: 0;
  padding: 0;
}
.close-page {
  position: absolute;
  top: -1.75em;
  font-size: 2em;
  font-weight: 100;
  text-transform: uppercase;
  left: 3.75em;
}
.close-page svg {
  position: relative;
  float: left;
  top: 0.35em;
  height: 0.7em;
  margin: 0 0.25em 0 0;
  width: 1em;
}
.close-page .text {
  position: relative;
  float: left;
}
@media screen and (max-width: 42.5em) {
  .close-page {
    left: 1em;
  }
}
.sub-page-view {
  position: absolute;
  z-index: 2000;
  top: 31em;
  text-align: justify;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
}
.sub-page-view .gold-border {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background: #c5baa8;
  background: -webkit-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -moz-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -o-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: -ms-linear-gradient(left, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
  background: linear-gradient(to right, transparent 0%, #c5baa8 30%, #c5baa8 80%, transparent 100%);
}
.sub-page-view p {
  font-size: 1.2em;
  line-height: 1.5em;
  margin: 0;
}
.sub-page-view p+p {
  margin-top: 1em;
}
.sub-page-view #page-content-wrapper {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  padding: 3em 7.5em;
  background: rgba(71,37,38,0.5);
  background: -webkit-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -webkit-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%), rgba(0,0,0,0.8);
  background: -moz-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -moz-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%), rgba(0,0,0,0.8);
  background: -o-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -o-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%), rgba(0,0,0,0.8);
  background: -ms-linear-gradient(top left, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), -ms-linear-gradient(left, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%), rgba(0,0,0,0.8);
  background: linear-gradient(to bottom right, rgba(149,41,45,0.15), rgba(149,41,45,0.15), transparent), linear-gradient(to right, transparent, transparent 10%, rgba(64,43,188,0.1) 30%, rgba(64,43,188,0.1) 70%, transparent 90%), rgba(0,0,0,0.8);
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 10em;
  -moz-column-gap: 10em;
  column-gap: 10em;
  -webkit-animation-fill-mode: backwards;
  -moz-animation-fill-mode: backwards;
  -o-animation-fill-mode: backwards;
  -ms-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation: pageTransition 375ms ease-in;
  -moz-animation: pageTransition 375ms ease-in;
  -o-animation: pageTransition 375ms ease-in;
  -ms-animation: pageTransition 375ms ease-in;
  animation: pageTransition 375ms ease-in;
}
@media screen and (max-width: 92.5em) {
  .sub-page-view #page-content-wrapper {
    -webkit-column-gap: 8em;
    -moz-column-gap: 8em;
    column-gap: 8em;
  }
}
@media screen and (max-width: 92.5em) {
  .sub-page-view #page-content-wrapper {
    -webkit-column-gap: 4em;
    -moz-column-gap: 4em;
    column-gap: 4em;
  }
}
@media screen and (max-width: 54em) {
  .sub-page-view #page-content-wrapper {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .sub-page-view #page-content-wrapper p {
    font-size: 1.75em;
  }
}
@media screen and (max-width: 42.5em) {
  .sub-page-view #page-content-wrapper {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    padding: 3em 2em;
  }
  .sub-page-view #page-content-wrapper p {
    font-size: 1.25em;
  }
}
@media screen and (min-width: 80em) {
  body {
    font-size: 100%;
  }
}
@media screen and (max-width: 80em) {
  body {
    font-size: 90%;
  }
}
@media screen and (max-width: 72em) {
  body #map-wrapper #map-container #map-canvas {
    top: -5%;
    right: -20%;
  }
}
@media screen and (max-width: 61.25em) {
  body {
    font-size: 85%;
  }
  body #map-wrapper #map-container #map-canvas {
    right: -30%;
  }
}
@media screen and (max-width: 54em) {
  body {
    font-size: 80%;
  }
  body #map-wrapper #map-container #map-canvas {
    right: -40%;
  }
}
@media screen and (max-height: 75em) {
  #header-component,
  #lightbox-mount header {
    padding: 5em 0 0 7.5em;
  }
  #header-component h1,
  #lightbox-mount h2 {
    font-size: 4em;
  }
  #publication-callouts {
    margin-top: 13.5em;
  }
  .sub-page-view {
    top: 28em;
  }
}
@media screen and (max-height: 61.875em) {
  #header-component,
  #lightbox-mount header {
    padding: 2em 0 0 7.5em;
  }
  #header-component h1,
  #lightbox-mount h2 {
    font-size: 4em;
    width: 20em;
  }
  #publication-callouts {
    margin-top: 4.5em;
  }
  .sub-page-view {
    top: 16em;
  }
}
@media screen and (max-height: 55em) {
  html,
  body {
    font-size: 95%;
  }
  #header-component,
  #lightbox-mount header {
    padding: 2em 0 0 7.5em;
  }
  #header-component h1,
  #lightbox-mount h2 {
    font-size: 3em;
  }
  #publication-callouts {
    margin-top: 4.5em;
  }
  .sub-page-view {
    top: 16em;
  }
}
@media screen and (max-height: 48em) {
  html,
  body {
    font-size: 90%;
  }
}
@media screen and (max-height: 44em) {
  html,
  body {
    font-size: 85%;
  }
}
@-moz-keyframes glow {
  0% {
    -webkit-filter: drop-shadow(0 0 7px #ff506f);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 15px #ff506f);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes glow {
  0% {
    -webkit-filter: drop-shadow(0 0 7px #ff506f);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 15px #ff506f);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes glow {
  0% {
    -webkit-filter: drop-shadow(0 0 7px #ff506f);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 15px #ff506f);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-ms-keyframes glow {
  0% {
    -webkit-filter: drop-shadow(0 0 7px #ff506f);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 15px #ff506f);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes glow {
  0% {
    -webkit-filter: drop-shadow(0 0 7px #ff506f);
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
  }

  100% {
    -webkit-filter: drop-shadow(0 0 15px #ff506f);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
@-moz-keyframes dropIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-15%) scale(1.1);
    -moz-transform: translateY(-15%) scale(1.1);
    -o-transform: translateY(-15%) scale(1.1);
    -ms-transform: translateY(-15%) scale(1.1);
    transform: translateY(-15%) scale(1.1);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@-webkit-keyframes dropIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-15%) scale(1.1);
    -moz-transform: translateY(-15%) scale(1.1);
    -o-transform: translateY(-15%) scale(1.1);
    -ms-transform: translateY(-15%) scale(1.1);
    transform: translateY(-15%) scale(1.1);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@-o-keyframes dropIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-15%) scale(1.1);
    -moz-transform: translateY(-15%) scale(1.1);
    -o-transform: translateY(-15%) scale(1.1);
    -ms-transform: translateY(-15%) scale(1.1);
    transform: translateY(-15%) scale(1.1);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@-ms-keyframes dropIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-15%) scale(1.1);
    -moz-transform: translateY(-15%) scale(1.1);
    -o-transform: translateY(-15%) scale(1.1);
    -ms-transform: translateY(-15%) scale(1.1);
    transform: translateY(-15%) scale(1.1);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@keyframes dropIn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-15%) scale(1.1);
    -moz-transform: translateY(-15%) scale(1.1);
    -o-transform: translateY(-15%) scale(1.1);
    -ms-transform: translateY(-15%) scale(1.1);
    transform: translateY(-15%) scale(1.1);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
@-moz-keyframes pageTransition {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
@-webkit-keyframes pageTransition {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
@-o-keyframes pageTransition {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
@-ms-keyframes pageTransition {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
@keyframes pageTransition {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: rotateX(45deg);
    -moz-transform: rotateX(45deg);
    -o-transform: rotateX(45deg);
    -ms-transform: rotateX(45deg);
    transform: rotateX(45deg);
  }

  100% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
}
